<template>
  <div>
    <b-card v-if="_.get(user, 'plan.commenting', false) || reviews.length">

      <div class="rating-part d-flex flex-column flex-md-row"
           :class="{'justify-content-center': !reviews.length}">
        <div class="col-md-3 average-rating" v-if="reviews.length">
          <h6>{{ averageScore }}</h6>
          <rating :value="averageScore" disabled class="mb-1" no-border></rating>
          <p class="mb-0">{{ $t('titles.average_rating') }}</p>
        </div>
        <div v-if="reviews.length"
             class="col-md-5 rating-progress d-flex flex-column justify-content-between my-4 my-md-0">
          <div class="d-flex flex-row align-items-center">
            <div class="progress flex-grow-1">
              <div class="progress-bar text-start ps-2 bg-success" role="progressbar"
                   :style="{width: getPercentsForScore(5) + '%'}"
                   :aria-valuenow="getPercentsForScore(5)" aria-valuemin="0" aria-valuemax="100">
                {{ $t('titles.stars.with_count', {stars: 5}) }}
              </div>
            </div>
            <div>
              ({{ _.filter(reviews, {score: 5}).length }})
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="progress flex-grow-1">
              <div class="progress-bar text-start ps-2 bg-primary" role="progressbar"
                   :style="{width: getPercentsForScore(4) + '%'}"
                   :aria-valuenow="getPercentsForScore(4)" aria-valuemin="0" aria-valuemax="100">
                {{ $t('titles.stars.with_count', {stars: 4}) }}
              </div>
            </div>
            <div>
              ({{ _.filter(reviews, {score: 4}).length }})
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="progress flex-grow-1">
              <div class="progress-bar text-start ps-2 bg-secondary" role="progressbar"
                   :style="{width: getPercentsForScore(3) + '%'}"
                   :aria-valuenow="getPercentsForScore(3)" aria-valuemin="0" aria-valuemax="100">
                {{ $t('titles.stars.with_count', {stars: 3}) }}
              </div>
            </div>
            <div>
              ({{ _.filter(reviews, {score: 3}).length }})
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="progress flex-grow-1">
              <div class="progress-bar text-start ps-2 bg-warning" role="progressbar"
                   :style="{width: getPercentsForScore(2) + '%'}"
                   :aria-valuenow="getPercentsForScore(2)" aria-valuemin="0" aria-valuemax="100">
                {{ $t('titles.stars.with_count', {stars: 2}) }}
              </div>
            </div>
            <div>
              ({{ _.filter(reviews, {score: 2}).length }})
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="progress flex-grow-1">
              <div class="progress-bar text-start ps-2 bg-danger" role="progressbar"
                   :style="{width: getPercentsForScore(1) + '%'}"
                   :aria-valuenow="getPercentsForScore(1)"
                   aria-valuemin="0" aria-valuemax="100">{{ $t('titles.stars.with_count', {stars: 1}) }}
              </div>
            </div>
            <div>
              ({{ _.filter(reviews, {score: 1}).length }})
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex flex-column" :class="{'px-md-5': reviews.length}"
             v-if="_.get(user, 'plan.commenting', false)">
          <h4 class="mb-1">{{ $t('questions.do_you_have_the_product_or_used_it') }}</h4>
          <b-button variant="primary" @click="createDialog = true">
            {{ $t('buttons.add_review') }}
          </b-button>
        </div>
      </div>
    </b-card>
    <div class="d-flex flex-column">
      <b-card class="comment-part d-flex flex-column flex-md-row p-2"
              no-body
              v-for="review in reviews" :key="review.id">
        <div class="user-img-part d-flex flex-row col-md-3">
          <div class="user-img circle flex-grow-0" v-if="_.get(review, 'user.avatar')">
            <b-img
                :src="review.user.avatar"
                class="img-fluid rounded-circle"></b-img>
          </div>
          <div class="user-text d-flex flex-column px-2 flex-grow-1">
            <h6 class="text-muted">{{ moment(review.createdAt.date).fromNow() }}</h6>
            <p class="h4">{{ review.user.name }}</p>
            <div class="mb-2 comment">
              <rating :value="review.score" disabled no-border class="p-0"></rating>
            </div>
          </div>
        </div>
        <div class="comment d-flex flex-grow-1 flex-column px-md-3">
          <h4 v-if="review.title">{{ review.title }}</h4>
          <div v-html="review.content" class="mb-3 mb-md-0"></div>
        </div>
        <div class="review-images-container row col-md-6 justify-content-md-end"
             v-if="review.images.length">
          <a :href="image.route" target="_blank" v-for="image in review.images" :key="image.id"
             :style="'--image: url('+image.route+')'"
             class="img-thumbnail review-image-link"
          >
          </a>
        </div>
      </b-card>
    </div>
    <b-modal v-model="createDialog" v-if="createDialog" :title="$t('titles.tell_us_what_you_think_about_this_product')"
             :ok-title="$t('buttons.save')"
             :cancel-title="$t('buttons.close')"
             centered
             @ok="submitReview"
             :ok-disabled="saveLoading || !isNewReviewValid"
    >
      <product-create-review :productId="productId" v-model="newReview"></product-create-review>
    </b-modal>
  </div>
</template>

<script>
import Rating from "@/views/pricex/elements/Products/Rating";
import {BButton, BModal, BCard, BImg} from "bootstrap-vue";
import _ from 'lodash'
import {mapActions} from "vuex";
import ProductCreateReview from "@/views/pricex/elements/Products/ProductCreateReview";

export default {
  components: {
    ProductCreateReview,
    BButton, BModal, BCard, BImg,
    Rating
  },
  props: {
    reviews: {
      type: Array,
      required: true
    },
    productId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      createDialog: false,
      saveLoading: false,
      newReview: {
        rating: 5,
        content: null,
        images: [],
        title: null
      }
    }
  },
  name: "ProductReviews",
  computed: {
    ratingScores() {
      return _.map(this.reviews, 'score');
    },
    averageScore() {
      return _.round(_.meanBy(this.reviews, r => r.score), 2);
    },
    isNewReviewValid() {
      return !_.isEmpty(this.newReview.content) && this.newReview.content.length > 10;
    },
    user() {
      return this.$store.state.user.data
    }
  },
  methods: {
    ...mapActions('reviews', ['storeReview']),
    getPercentsForScore(score) {
      if (this.reviews.length === 0) {
        return 0;
      }
      let scoreItems = _.filter(this.reviews, {score: score}).length;

      return Math.ceil((scoreItems / this.reviews.length) * 100);
    },
    submitReview() {
      this.saveLoading = true;
      this.newReview.product_id = this.productId;
      this.storeReview(this.newReview)
          .then(response => {
            this.createDialog = false;
            this.newReview = {
              rating: 5,
              content: null,
              images: [],
              title: null
            }
            this.$emit('change', response.data.review);
          })
          .finally(() => {
            this.saveLoading = false;
          })
    },
  }
}
</script>

<style scoped lang="scss">
.review-images-container {
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
}

.review-image-link {
  background-image: var(--image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 50px;
}

.heading-title h6 {
  font-size: 2rem;
}

.average-rating {
  text-align: center;

  > h6 {
    font-size: 4rem;
  }

  > p {
    font-size: 1.5rem;
  }

}

.average-rating::v-deep {
  .el-rate__icon {
    font-size: 2rem !important;
  }
}
</style>
