<template>
  <div>
    <swiper
        class="swiper-responsive-breakpoints px-1"
        style="display: grid"
        :options="swiperOptions"
        ref="swiper"
    >
      <swiper-slide v-for="product in products"
                    class="h-100"
                    :key="product.id">
        <product-listing :product="product"
                         class="h-100"
                         :preview="!disablePreview"
                         @preview="previewProduct = product"></product-listing>
      </swiper-slide>
      <div
          slot="button-next"
          class="swiper-button-next"
      />
      <div
          slot="button-prev"
          class="swiper-button-prev"
      />
    </swiper>
    <product-preview :product="previewProduct" @next="nextProduct"
                     :visible="previewProduct !== null"
                     @hidden="() => previewProduct = null"
                     @close="() => previewProduct = null"
                     v-if="previewProduct !== null"
                     @previous="previousProduct">
    </product-preview>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import ProductListing from "@/views/pricex/elements/Products/ProductListing";
import ProductPreview from "@/views/pricex/elements/Products/ProductPreview";
import _ from 'lodash'

export default {
  components: {
    ProductListing,
    ProductPreview,
    Swiper, SwiperSlide
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    disablePreview: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageLoading: {
      type: Boolean,
      default: false
    }
  },
  name: "SimilarProducts",
  data() {
    return {
      previewProduct: null,
      swiperOptions: {
        slidesPerView: 6,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoHeight: false,
        grabCursor: false,
        preloadImages: true,
        lazy: false,
        breakpoints: {
          2000: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      }
    }
  },
  methods: {
    nextProduct() {
      let index = _.findIndex(this.products, ['id', this.previewProduct.id]);
      if (index === this.products.length - 1) {
        return;
      }
      this.previewProduct = this.products[index + 1];
    },
    previousProduct() {
      let index = _.findIndex(this.products, ['id', this.previewProduct.id]);
      if (index === 0) {
        return;
      }
      this.previewProduct = this.products[index - 1];
    }
  }
}
</script>
<style>
:root{
  --swiper-navigation-size: 34px
}
</style>
