<template>
  <div
      class="product-actions px-md-5 px-2 py-md-5 py-2 flex-column align-items-center">
    <div class="d-flex flex-row text-center text-md-start">
      <!--                <p-input-number class="col-md-8 flex-grow-0" :value="orderQuantity" :min="1" :max="9999" readonly-->
      <!--                                @input="v => orderQuantity = v"></p-input-number>-->
      <h6 class="text-center w-100 price-container">
        <price :value="product.price / 100"></price>
      </h6>
    </div>
    <div v-if="freeDelivery">
      {{ $t('messages.shipping') }} <span class="text-primary">{{ $t('messages.free') }}</span>
    </div>
    <div class="col-md-16 d-flex flex-column">
      <b-link :href="product.affiliateLink || product.url" target="_blank"
              class="btn-primary btn-lg mt-2 buy-button rounded-pill text-white text-center shadow-lg">
        {{ $t('buttons.view_on', {site: _.get(product, 'website.name')}) }}
      </b-link>
      <b-link class="text-center btn-lg mt-2 fw-bolder" @click="openLowerPriceDialog">
        <h4><u>{{ $t('buttons.i_want_to_pay_lower_price') }}</u></h4>
      </b-link>
      <!--                <p-button class="btn-outline-secondary btn-lg mt-3 buy-leasing-button rounded-pill text-black-50 py-3">-->
      <!--                    Buy on leasing-->
      <!--                </p-button>-->
      <!--                <p-button class="btn-link btn-sm mt-3 fw-bolder">Best offer <span class="text-primary"><u>12 x 45</u></span>-->
      <!--                </p-button>-->
      <add-to-favorites-button class="text-center mt-1" withLabel :product="product"></add-to-favorites-button>
      <b-link class="compare-button mb-3 text-primary text-center mt-1"
              @click="toggleCompare"
      >
        <feather-icon :icon="productAddedToCompare ? 'CheckSquareIcon' : 'SquareIcon'"></feather-icon>
        {{ $t('buttons.compare') }}
      </b-link>
    </div>
    <hr v-if="guarantee || returnPolicy || checkingPolicy">
    <div class="d-flex flex-column">
      <div class="mb-3 d-flex flex-row" v-if="guarantee">
        <div class="me-2">
          <feather-icon icon="ShieldIcon"></feather-icon>
        </div>
        <div>
          <div class="fw-bold">{{ $t('label.guarantee') }}</div>
          <div>{{ guarantee }}</div>
        </div>
      </div>
      <div class="mb-3 d-flex flex-row" v-if="returnPolicy">
        <div class="me-2">
          <feather-icon icon="ArrowLeftCircleIcon"></feather-icon>
        </div>
        <div>
          <div class="fw-bold">{{ $t('label.return') }}</div>
          <div>{{ returnPolicy }}</div>
        </div>
      </div>
      <div class="mb-3 d-flex flex-row" v-if="checkingPolicy">
        <div class="me-2">
          <feather-icon icon="PackageIcon"></feather-icon>
        </div>
        <div>
          <div class="fw-bold">{{ $t('label.checking_goods') }}</div>
        </div>
      </div>

      <div class="banner flex-grow-0 py-3 py-md-0 text-center mt-3" v-if="showAd">
        <ad-banner :width="300" :height="250" :key="adContainerIndex"></ad-banner>
      </div>
    </div>
    <b-modal v-model="lowerPriceDialog" width="30%" centered>
      <template>
        <div v-if="!$can('manage', 'User')" class="d-flex justify-content-center flex-column">
          <p>{{ $t('messages.you_need_to_login_into_your_account_to_be_able_to') }}</p>
        </div>
        <div v-if="$can('manage', 'User')">
          <p class="text-center">
            {{ $t('messages.write_down_the_price_that_fit_into_your_budget') }}
          </p>
          <div class="col-lg-12 mx-auto">
            <b-form-group
                :label="$t('label.price')"
                label-for="number"
                autofocus
            >
              <cleave
                  id="number"
                  v-model="expectedPrice"
                  class="form-control"
                  :raw="false"
                  :options="{numeral: true, numeralThousandsGroupStyle: 'thousand'}"
                  placeholder="10,000"
              />
            </b-form-group>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer="{close}">
        <b-button :to="{name: 'auth.login'}" class="btn-success text-white" v-if="!$can('manage', 'User')">
          {{ $t('buttons.login') }}
        </b-button>
        <b-button class="btn-success" @click="createNotification" v-if="$can('manage', 'User')">
          {{ $t('buttons.notify_me') }}
        </b-button>
        <b-button class="btn-secondary" @click="close">{{ $t('buttons.close') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Price from "@/views/pricex/elements/Price";
import AddToFavoritesButton from "@/views/pricex/elements/Products/AddToFavoritesButton";
import {BButton, BLink, BModal, BFormGroup} from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import _ from "lodash"
import {mapActions} from "vuex";
import AdBanner from "@/views/pricex/elements/AdBanner";

export default {
  components: {
    AdBanner,
    BButton, BLink, BModal, BFormGroup, Cleave,
    AddToFavoritesButton, Price
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    showAd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lowerPriceDialog: false,
      expectedPriceInput: 0,
      adContainerIndex: 0
    }
  },
  computed: {
    guarantee() {
      return _.get(_.find(this.product.attributes, {code: "WARNT"}), 'value');
    },
    returnPolicy() {
      return _.get(_.find(this.product.attributes, {code: "RETRN"}), 'value');
    },
    checkingPolicy() {
      return _.get(_.find(this.product.attributes, {code: "CHECK"}), 'value') === true;
    },
    freeDelivery() {
      return _.get(_.find(this.product.attributes, {code: "FDELV"}), 'value')
    },
    expectedPrice: {
      get() {
        return this.expectedPriceInput
      },
      set(v) {
        this.expectedPriceInput = v;
      }
    },
    productAddedToCompare() {
      return _.find(this.$store.state.compare.items, {id: this.product.id});
    }
  },
  mounted() {
    this.expectedPrice = this.product.price / 100;
  },
  methods: {
    ...mapActions('product_notifications', ['storeNotification']),
    openLowerPriceDialog() {
      this.lowerPriceDialog = true;
    },
    createNotification() {
      this.storeNotification({
        product_id: this.product.id,
        expected_price: this.expectedPrice
      }).then(() => {
        this.$notify().success(this.$t('notifications.lower_price_notification_saved'), this.$t('notifications.lower_price_title'))
        this.lowerPriceDialog = false
      })
    },
    toggleCompare() {
      if (!this.productAddedToCompare) {
        let product = _.cloneDeep(this.product);
        product.image = _.get(this.product, 'images.0.filepath');
        this.$store.dispatch('compare/add', product);
        return;
      }
      this.$store.dispatch('compare/remove', this.product.id);
    }
  },
  name: "ProductPageActions",
  watch: {
    product() {
      this.expectedPrice = this.product.price / 100;
      this.adContainerIndex++
    }
  }
}
</script>
<style scoped>
.price-container {
  font-size: 300%;
}
</style>
