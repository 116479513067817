<template>
  <div class="d-flex flex-column">
    <b-form-group :label="$t('questions.how_many_stars_you_give_to_the_product')">
      <rating v-model="review.rating" no-border class="p-0" show-value></rating>
    </b-form-group>
    <b-form-group :label="$t('label.review')">
      <b-form-textarea v-model="review.content" :placeholder="$t('placeholder.tell_us_your_opinion_about_the_product')"
                       minlength="10"
                       maxlength="300" show-word-limit></b-form-textarea>
    </b-form-group>
    <p class="small text-danger" v-if="review.content === null || review.content.length < 10">
      {{ $t('errors.please_enter_enough_text_for_your_opinion') }}
    </p>
    <b-form-group :label="$t('label.review_title_optional')">
      <b-form-input v-model="review.title"></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('label.gallery_optional')">
      <small>{{ $t('gallery.counter', {of: review.images.length, to: 5}) }}</small>
    </b-form-group>
    <div class="d-flex flex-row">
      <div class="col-md-2 position-relative" v-for="(file, index) in review.images" :key="file.uuid">
        <b-img :src="file.data" fluid></b-img>
        <confirmation @true="review.images.splice(index, 1)" :question="$t('questions.remove_image')">
          <template #activator="{showDialog}">
            <b-button size="sm" class="p-0 remove-image-button position-absolute" variant="danger" @click="showDialog">
              <feather-icon icon="XIcon"></feather-icon>
            </b-button>
          </template>
        </confirmation>
      </div>
    </div>
    <div class="upload-container mt-4">
      <b-form-file
          action="#"
          accept="image/png,image/jpeg"
          ref="upload"
          multiple
          @change="handleFile"
          :limit="5"
          :disabled="review.images.length >= 5"
          :placeholder="$t('placeholder.no_file_chosen')"
          :drop-placeholder="$t('placeholder.drop_files')"
          :browse-text="$t('buttons.choose_files')">
        <i slot="default" class="el-icon-plus"></i>
      </b-form-file>
    </div>
  </div>
</template>

<script>
import Rating from "@/views/pricex/elements/Products/Rating";
import {BFormTextarea, BFormInput, BFormFile, BFormGroup, BImg, BButton} from "bootstrap-vue";
import _ from 'lodash'
import Confirmation from "@/views/pricex/elements/Confirmation";

export default {
  name: "ProductCreateReview",
  components: {
    BFormTextarea, BFormInput, BFormFile, BFormGroup, BImg, BButton,
    Rating, Confirmation
  },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      saveLoading: false
    }
  },
  computed: {
    review: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  methods: {
    async handleFile(event) {
      for (const file of event.target.files) {
        let image = await this.toBase64(file);
        this.review.images.push({
          'uid': file.uid,
          'data': image
        });
        if (this.review.images.length >= 5) {
          return;
        }
      }
    },
    handleRemoval(file) {
      let fileIndex = _.findIndex(this.review.images, {uid: file.uid});
      if (fileIndex > -1) {
        this.review.images.splice(fileIndex, 1);
      }
    }
  }
}
</script>
<style scoped>
.remove-image-button {
  top: 0;
  right: 0;
}
</style>