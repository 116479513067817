<template>
  <div class="pt-2 px-md-3 pb-2 col-lg-8 col-xl-9">
    <b-breadcrumb class="font-small-3 pl-0">
      <b-breadcrumb-item :to="{name: '/'}">{{ $t('buttons.home') }}</b-breadcrumb-item>
      <b-breadcrumb-item active>{{ product.name }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <h1 class="my-2 font-weight-bolder">{{ product.name }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" xl="5">
        <b-card>
          <swiper
              class="swiper-paginations text-center h-100"
              :options="swiperOptions"
          >
            <swiper-slide
                v-for="(img,index) in images"
                :key="index"
                style="min-height: 250px"
                class="d-flex align-items-center justify-content-center"
            >
              <b-img
                  :src="img.src"
                  fluid
                  :alt="img.alt"
                  @error="replaceImage"
              />
            </swiper-slide>

            <div
                v-if="images.length > 1"
                slot="pagination"
                class="swiper-pagination"
            />
          </swiper>
        </b-card>
      </b-col>
      <b-col class="d-flex flex-column" lg="6">
        <span v-if="product.isActive === true"><feather-icon icon="CheckIcon"
                                                             class="text-success"></feather-icon>{{
            $t('availability.in_stock')
          }}</span>
        <span v-if="product.isActive === false"><feather-icon icon="SlashIcon"
                                                              class="text-danger"></feather-icon>{{
            $t('availability.not_available')
          }}</span>
        <div class="py-1">{{ $t('titles.product_offered_by') }}
          <b-link :href="'https://'+_.get(product, 'website.domain')" class="btn-sm">
            {{ _.get(product, 'website.name') }}
          </b-link>
        </div>
        <rating :value="_.get(product, 'rating.score')" disabled no-border class="p-0 d-inline-flex"
                style="width: 100px" inline></rating>
        <ad-banner :width="728" :height="90"></ad-banner>
      </b-col>
    </b-row>
    <b-row v-if="['lg', 'xl'].indexOf(breakpoint) === -1">
      <b-col>
        <b-card class="mt-1">
          <product-page-actions :product="product"></product-page-actions>
        </b-card>
        <ad-banner :width="300" :height="250" class="d-block text-center mb-3"></ad-banner>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-card-title class="d-flex flex-column flex-md-row justify-content-md-between">
            <div class="flex-grow-1">{{ $t('titles.price_history') }}</div>
            <div class="flex-grow-0 mt-1 mt-md-0">
              <b-form-select :options="historyPeriodOptions" v-model="historyPeriod" size="sm"></b-form-select>
            </div>
          </b-card-title>
          <b-overlay :show="chartLoading">
            <div v-if="chartData && chartData.labels.length > 0">
              <chart :data="chartData" :height="200" :key="chartIndex"></chart>
            </div>
            <div v-else>
              <b-alert variant="info" show class="px-2 py-2">
                {{ $t('messages.there_is_no_available_price_history_for_selected') }}
              </b-alert>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="similar.length" class="mb-3">
      <b-col class="p-0">
        <ad-banner :width="980" :height="200" class="d-block text-center" :breakpoints="['lg', 'xl']"></ad-banner>
        <h3 class="mt-md-5 mb-2 px-1 ">{{ $t('titles.similar_products') }}</h3>
        <similar-products :products="similar"></similar-products>
      </b-col>
    </b-row>
    <b-row v-if="!_.isEmpty(product.description)" class="mb-md-3">
      <b-col>
        <ad-banner :width="300" :height="250" class="d-block text-center mb-3" :breakpoints="['xs', 'sm']"></ad-banner>
        <b-card>
          <b-card-title>{{ $t('titles.overview') }}</b-card-title>
          <div v-html="product.description"></div>
        </b-card>
        <ad-banner :width="980" :height="200" class="d-block text-center" :breakpoints="['lg', 'xl']"></ad-banner>
      </b-col>
    </b-row>
    <b-row v-if="_.get(product, 'attributes', []).length">
      <b-col>
        <b-card>
          <b-card-title>{{ $t('titles.characteristics') }}</b-card-title>
          <table class="table table-striped">
            <tbody>
            <tr v-for="(attribute, index) in product.attributes" :key="index">
              <td class="col-xs-4 text-muted">{{ attribute.name }}</td>
              <td class="col-xs-8">{{ attribute.value }}</td>
            </tr>
            </tbody>
          </table>
        </b-card>
        <ad-banner :width="300" :height="250" class="d-block text-center mb-3" :breakpoints="['xs', 'sm']"></ad-banner>
        <ad-banner :width="980" :height="200" class="d-block text-center" :breakpoints="['lg', 'xl']"></ad-banner>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <product-reviews :reviews="product.reviews" :productId="product.id" v-if="product.id"
                         @change="addReviewToProduct"></product-reviews>
      </b-col>
    </b-row>
    <b-sidebar right :visible="['lg', 'xl'].indexOf(breakpoint) > -1" :width="breakpoint === 'xl' ? '25%' : '33%'"
               class="position-relative border-left" no-header z-index="1"
               bg-variant="white"
               no-close-on-route-change
               :shadow="true">
      <product-page-actions :product="product" class="mt-5" show-ad></product-page-actions>
    </b-sidebar>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BSidebar,
  BImg,
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BFormSelect,
    BAlert,
    BOverlay
} from "bootstrap-vue";
import {mapActions} from "vuex";
import _ from "lodash"
import 'swiper/css/swiper.css'
import Rating from "@/views/pricex/elements/Products/Rating";
import Chart from "@/views/pricex/elements/Chart";
import ProductReviews from "@/views/pricex/elements/Products/ProductReviews";
import ProductPageActions from "@/views/pricex/elements/Products/ProductPageActions";
import SimilarProducts from "@/views/pricex/elements/Products/SimilarProducts";
import AdBanner from "@/views/pricex/elements/AdBanner";

export default {
  components: {
    AdBanner,
    SimilarProducts,
    ProductPageActions,
    ProductReviews,
    BBreadcrumb, BBreadcrumbItem, BSidebar, BImg, BCol, BRow,
    BCard, BLink, BCardTitle, BFormSelect, BAlert, BOverlay,
    Swiper, SwiperSlide, Rating, Chart
  },
  name: "Product",
  metaInfo() {
    return {
      title: this.product.name,
      meta: [
        {vmid: 'og:title', property: 'og:title', content: this.product.name + ' | ' + 'PriceX'},
        {vmid: 'og:image', property: 'og:image', content: _.get(_.first(this.images), 'src')},
        {
          vmid: 'og:description',
          property: 'og:description',
          content: _.get(this.product, 'description', '').replace(/<[^>]*>?/gm, '')
        },
      ]
    }
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
      similar: [],
      similarCount: 20,
      historyPeriod: 'lastYear',
      historyPeriodOptions: [
        {value: 'lastMonth', text: this.$t('history_period.last_month')},
        {value: 'lastTwoWeeks', text: this.$t('history_period.last_two_weeks')},
        {value: 'lastWeek', text: this.$t('history_period.last_week')},
        {value: 'lastYear', text: this.$t('history_period.last_year')},
        {value: 'allTime', text: this.$t('history_period.all_time')}
      ],
      chartLoading: false,
      chartIndex: 0
    }
  },
  computed: {
    product() {
      return this.$store.state.products.product;
    },
    chartData() {
      return this.product.chart;
    },
    images() {
      return _.map(this.product.images, image => {
        return {
          'src': image.filepath,
          'alt': this.product.name
        }
      })
    },
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"]
    },
  },
  mounted() {
    this.loadProduct({
      product_id: this.$route.params.product,
      period: this.historyPeriod
    }).catch(() => {
      this.$router.push({name: 'error-404'});
    })
    this.loadSimilar(this.$route.params.product, 0, this.similarCount)
  },
  methods: {
    ...mapActions('products', ['loadProduct', 'addReview', 'loadChart']),
    ...mapActions('search', ['getSimilar']),
    loadSimilar(productId, from, size) {
      this.similar = [];
      this.getSimilar({product_id: productId, size: size, from: from})
          .then(data => {
            for (let i = 0; i < data.hits.hits.length; i++) {
              this.similar.push(data.hits.hits[i]._source)
            }
          })
    },
    replaceImage(e) {
      e.target.src = '/logo.svg';
    },
    addReviewToProduct(review) {
      this.addReview(review)
    }
  },
  watch: {
    $route(from) {
      this.loadProduct({product_id: from.params.product, period: this.historyPeriod})
          .then(() => {
            this.chartIndex++
          })
          .catch(() => {
            this.$router.push({name: 'error-404'});
          })
      this.loadSimilar(from.params.product, 0, this.similarCount)
    },
    historyPeriod() {
      this.chartLoading = true
      this.loadChart({product_id: this.product.id, period: this.historyPeriod})
          .finally(() => {
            this.chartLoading = false
            this.chartIndex++
          })
    }
  }
}
</script>
